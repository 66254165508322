import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  pageIndex$ = new BehaviorSubject<number>(1);

  pageSize$ = new BehaviorSubject<number>(50);

  lengthList$: BehaviorSubject<{
    count: number;
    skipToFirstPage: boolean;
  }> = new BehaviorSubject<{
    count: number;
    skipToFirstPage: boolean;
  }>({ count: 0, skipToFirstPage: false });

  isSearching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
