import { createAction, props } from '@ngrx/store';

export const setLoaded = createAction('[Objects] Set Loaded', props<{ loaded: boolean }>());

export const getObjects = createAction('[Objects] Get');
export const storeObjects = createAction('[Objects] Store', props<{ list: any }>());
export const updateLastMessageForObjects = createAction(
  '[Objects] Update last message',
  props<any>(),
);

export const updateConnectedStatusForObject = createAction(
  '[Objects] Update connected status',
  props<any>(),
);

export const updateObjectById = createAction(
  '[Objects] Update object by id',
  props<{ updatedObject: any }>(),
);

export const deleteObjectById = createAction(
  '[Objects] Delete object by id',
  props<{ deletedObjectId: any }>(),
);
export const addObject = createAction('[Objects] Add new object', props<any>());
