import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDrawOptions, Geozone, GeozoneTypeEnum } from '../types/geozoneTypes';
import { LatLngLiteral } from 'leaflet';
import {
  DrawOverSpeedsForTrack,
  DrawParkingsForTrack,
  DrawPlumsForTrack,
  DrawRefuelsForTrack,
  DrawStopsForTrack,
} from '../types/track-event';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public selectedObjects: any = new BehaviorSubject([]);
  public drawMapObjects = new BehaviorSubject([]);
  public isVisibleObjectsName = new BehaviorSubject<boolean>(false);
  public isVisibleGeozoneName = new BehaviorSubject<boolean>(false);

  public updateTrackObjectPoint = new Subject();
  public panTo = new Subject<LatLngLiteral>();
  public setDrawMode = new Subject<boolean>();
  public changeDrawOptions = new Subject<ChangeDrawOptions>();
  public clearMapGeozone = new Subject();
  public changeObjectMarkerPos = new Subject();
  public deleteObjectMarker = new Subject();
  public editGeozone = new Subject<Geozone<GeozoneTypeEnum> | null>();

  public showStartTrack = new Subject();
  public showFinishTrack = new Subject();
  public drawStopsForTrack = new Subject<DrawStopsForTrack>();
  public drawParkingsForTrack = new Subject<DrawParkingsForTrack>();
  public drawRefuelsForTrack = new Subject<DrawRefuelsForTrack>();
  public drawPlumsForTrack = new Subject<DrawPlumsForTrack>();
  public drawOverSpeedForTrack = new Subject<DrawOverSpeedsForTrack>();
  public drawStartEndsForTrack = new Subject();

  public zoomOnMap = new Subject();
  public zoomBuildingOnMap = new Subject<LatLngLiteral>();

  public objForPopup = new Subject();

  constructor(private httpClient: HttpClient) {}

  getAddressByCoordinates(lat: number, lng: number) {
    return this.httpClient
      .post('geocoding/reverse/', {
        coordinates: [{ lat, lng }],
      })
      .pipe(
        map((res: any) => {
          const key = Object.keys(res)[0];
          return res[key].address;
        }),
      );
  }

  getAddress(coordinates: any) {
    return this.httpClient.post('geocoding/reverse/', {
      coordinates,
    });
  }
}
