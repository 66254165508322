import { createReducer, on } from '@ngrx/store';
import {
  addGeozone,
  deleteGeozoneById,
  storeGeozones,
  updateGeozoneById,
} from '../actions/geozones.actions';

export const initialState: any = {
  list: [],
};

export const geozonesReducer = createReducer(
  initialState,
  on(storeGeozones, (state, { list }) => ({ ...state, list })),

  on(updateGeozoneById, (state, data) => {
    const { updatedGeozone, params } = data;
    const list = state.list.map((geozone: any) => {
      return geozone.id === updatedGeozone.id ? { ...geozone, ...updatedGeozone } : geozone;
    });

    return { ...state, list };
  }),

  on(addGeozone, (state, data) => {
    const { createdGeozone, params } = data;
    const existingcreatedGeozone = state.list.find((obj: any) => obj.id === createdGeozone.id);

    if (!existingcreatedGeozone) {
      const newList = [createdGeozone, ...state.list];
      return {
        ...state,
        list: newList,
      };
    }

    return state;
  }),
  on(deleteGeozoneById, (state, data) => {
    const { deletedGeozoneId } = data;
    return {
      ...state,
      list: state.list.filter((geozone: any) => geozone.id !== deletedGeozoneId),
    };
  }),
);
