import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Geozone, GeozoneTypeEnum } from '../app/portal/components/map/types/geozoneTypes';

@Injectable({
  providedIn: 'root',
})
export class GeozonesService {
  public createGeozoneFormVisible: BehaviorSubject<{
    isActive: boolean;
    action: 'create' | 'edit' | null;
  }> = new BehaviorSubject<{ isActive: boolean; action: 'create' | 'edit' | null }>({
    isActive: false,
    action: null,
  });
  public editedGeozone: BehaviorSubject<Geozone<GeozoneTypeEnum> | null> =
    new BehaviorSubject<Geozone<GeozoneTypeEnum> | null>(null);
  public createGeozoneSquare: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public createGeozonePerimeter: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public createGeozonePerimeterMeters: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public createGeozoneRadius: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public createGeozoneDestroyer = new Subject();
  public deleteGeozone: Subject<number> = new Subject<number>();
  public goToGeozoneBounds: Subject<Geozone<GeozoneTypeEnum>> = new Subject<
    Geozone<GeozoneTypeEnum>
  >();

  public currentDrawLayer: any = null;

  public actionGeozoneText: string = 'Creating geofence';

  constructor(private httpClient: HttpClient) {}

  createGeozone(body: any) {
    return this.httpClient.post('geozone/', body);
  }

  updateGeozone(geozoneId: number, body: any) {
    return this.httpClient.put(`geozone/${geozoneId}/`, body);
  }

  createGeozonesGroup(body: any) {
    return this.httpClient.post('geozones/groups/', body);
  }

  list() {
    return this.httpClient.get('geozone/');
  }

  geozonesGroupList() {
    return this.httpClient.get('geozones/groups/');
  }

  updateGeozonesGroup(body: any, geozone_id: number) {
    return this.httpClient.put(`geozones/groups/${geozone_id}/`, body);
  }

  delete(geozone_id: number) {
    return this.httpClient.delete(`geozone/${geozone_id}/`);
  }

  deleteGroupGeozones(geozone_id: number) {
    return this.httpClient.delete(`geozones/groups/${geozone_id}/`);
  }
}
