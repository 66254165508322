import {
  AvailableDateTimePeriod,
  AvailableRecentlyDateTimePeriodTypes,
  DateTimePeriodParams,
} from '@common/components/date-time-period/date-time-period.component';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { CreateTrackParams } from '@services/tracks.service';
import {
  deleteTracksFromMessagesPage,
  setLastPageStateMessages,
  setParamsCreateTrackForMessages,
} from '@store/actions/messages.actions';
import { ResponseStats } from '@services/messages.service';

export enum AvailableTabsOnMessagesPage {
  Form = 'form',
  Result = 'result',
}

export enum AvailableMessagesTypes {
  WithData = 'with_data',
  SendedCommands = 'sended_commands',
  TrackPlayer = 'track_player',
  Events = 'events',
}

export enum AvailableMessagesParam {
  Raw = 'raw',
  Sensors = 'sensors',
}

export type FormDataMessagesPage = {
  objectId: number | null;
  dateTimePeriod: DateTimePeriodParams;
  messagesType: AvailableMessagesTypes;
  messagesParamsType: AvailableMessagesParam;
};

export type LastMessagesPageStateWithTabs = {
  activeTabOnPage: AvailableTabsOnMessagesPage;
  formData: FormDataMessagesPage;
  stats: ResponseStats | null;
  paramsCreateTrack: {
    [key: string]: CreateTrackParams;
  };
};

export type MessagesState = {
  lastPageState: LastMessagesPageStateWithTabs;
};

export const initialStateMessages: MessagesState = {
  lastPageState: {
    activeTabOnPage: AvailableTabsOnMessagesPage.Form,
    formData: {
      objectId: null,
      messagesType: AvailableMessagesTypes.WithData,
      messagesParamsType: AvailableMessagesParam.Raw,
      dateTimePeriod: {
        periodDateStart: null,
        periodDateEnd: null,
        periodValue: AvailableDateTimePeriod.Today,
        recentlyPeriod: 1,
        recentlyPeriodType: AvailableRecentlyDateTimePeriodTypes.Days,
        currentPeriod: false,
      },
    },
    stats: null,
    paramsCreateTrack: {},
  },
};

export const messagesReducer: ActionReducer<MessagesState> = createReducer(
  initialStateMessages,
  on(setLastPageStateMessages, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
  on(setParamsCreateTrackForMessages, (state, { params, cacheKey }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: { [cacheKey]: params },
      },
    };
  }),
  on(deleteTracksFromMessagesPage, (state) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: {},
      },
    };
  }),
);
